// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// load fonts
WebFont.load({
    google: { // add &display=swap to end of every font family
        families: ['Raleway:400,500,700&display=swap']
    },
    active: function() { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true 
    }
});

// Load BS
require('bootstrap');

//Load Libraries
require('flatpickr/dist/flatpickr.min');
require('select2/dist/js/select2.full.min');

//Load Modules
require('./modules/accordion');
require('./modules/formInputs');
require('./modules/modal');
require('./modules/scrollTo');
